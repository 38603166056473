
export function getLangText(lang,key){
    const enus = {
        dashboard:'Dashboard',
        market:'Marketplace',
        website:'Website',
        bottom_text_left:'Havent downloaded the blocto wallet yet?',
        bottom_text_right:'Go download',
        login_btn:'Login&SignIn with Blocto Wallet',
        logout_btn:'Logout',
        loading:'Loading...'
    };
    const zhcn = {
        dashboard:'仪表板',
        market:'商城',
        website:'官网',
        bottom_text_left:'还未下载Blocto钱包？',
        bottom_text_right:'去下载',
        login_btn:'使用Blocto钱包注册&登录',
        logout_btn:'退出登录',
        loading:'加载中，请稍后...'
    };
    //return lang;
    if(lang == 'en-us'){
        return enus[key];
    }else{
        return zhcn[key];
    }
     
}

export function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}
