import React, { useState, useEffect } from "react"
import * as fcl from "@blocto/fcl"

import globalConfig from '../globalConfig';
import * as utils from '../utils';


var initpage = true; //当前页面是否首次加载，用于已登录退出钱包
var postrun = false; //当前是否已提交登录信息至服务器
var clicklogin = false;  //是否手动点击登录

let lang = utils.getQueryVariable('lang') ? utils.getQueryVariable('lang'):'en-us';

const SignInOutButton = ({ user: { loggedIn } }) => {
  const [runstatus, setRun] = useState(0);
  const signInOrOut = async (event) => {
    event.preventDefault()

    if (loggedIn) {
      fcl.unauthenticate()
    } else {
      if(runstatus == 0){
        clicklogin = true;
        setRun(1)
        fcl.authenticate()
      }else if(runstatus == 1){
        return true;
      }
    }
    
  }
  var btnText = '';
  if (loggedIn) {
    btnText = utils.getLangText(lang,'logout');
  }else{
    if(runstatus == 0){
      btnText = utils.getLangText(lang,'login_btn');
    }else if(runstatus == 1){
      btnText = utils.getLangText(lang,'loading');
    }
  }
  

  return (
    <div className="sign-button blue" onClick={signInOrOut}>
      {btnText}
    </div>
  )
}



const CurrentUser = () => {
  const [user, setUser] = useState({})
 

  useEffect(() =>
    fcl
      .currentUser()
      .subscribe(user => setUser({ ...user }))
    , [])

   

   if(user && user.loggedIn && initpage){
      fcl.unauthenticate(); //首次进入页面如果检测已登录则退出钱包登录
      initpage = false;
   }
   
   if(user && user.loggedIn && clicklogin && !postrun){
    console.log(user)
    //runTransaction(user.addr);
    const bodydata = {'addr':user.addr,'email':user.services[0].scoped.email}
    fetch(globalConfig.mallurl+'/index.php/Member/login',{
           method:'POST',
           //mode:'no-cors',
           headers:{
               //'Accept':'application/json,text/plain,*/*',
               'Content-Type':'application/x-www-form-urlencoded'
           },
           body:JSON.stringify(bodydata)
       }).then((response)=>{
           return response.json()
       }).then((data)=>{
         console.log(data)
         if(data.errno == 0){
           /*if(data.data.provisionaccountstatus == 0){
              //provisionaccountTransaction(user);
              window.location.href="http://localhost:3000/?action=provisionAccount&addr="+user.addr+"&formpage=login";
           }else{
             window.location.href=globalConfig.mallurl+"/index.php/Index/index/loginSign/"+data.data.sign;
           }*/
           window.location.href=globalConfig.mallurl+"/index.php/Index/index/loginSign/"+data.data.sign;
         } 
       }).catch(function(error){
           console.log(error)
       })
      postrun = true;
   }
  return (
      //<button onClick={handleOnClick}>save</button>
      <SignInOutButton user={user} />
  )
}

export default CurrentUser
