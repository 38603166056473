import React, { useState } from 'react';
import styled from 'styled-components'


import commoncss from './resource/common.css'
import fontawesomecss from './resource/font-awesome.min.css'


import Section from './components/Section'
import Header from './components/Header'
import Footer from './components/Footer'

import globalConfig from './globalConfig';
import * as utils from './utils';
 
import Authenticate from './demo/Authenticate'
import Provision from './demo/Provision'


const Wrapper = styled.div`
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
`;

let action = utils.getQueryVariable('action')? utils.getQueryVariable('action'):'loginAccount';
let lang = utils.getQueryVariable('lang') ? utils.getQueryVariable('lang'):'en-us';


function App() {
  
  const [isShowMobileNav, changeShowMobileNav] = useState(0)

  const setMobileNavStatus = async (event) => {
    event.preventDefault()
    if(isShowMobileNav == 1){
      changeShowMobileNav(0)
    }else{
      changeShowMobileNav(1)
    }
   
  }

  if(action == 'loginAccount'){
    return (
      <Wrapper> 
        <Header className="top-section">
          <div className="top-section-wrapper">
            <div className="top-section-wrapper-left inlineblock-middle">
              <a className="inlineblock-middle" href={globalConfig.mallurl+'/index.php/Home/Index/index'}><img src={require('./resource/logo.png')}/></a>
              <div className="top-section-wrapper-leftnav inlineblock-middle">
			          <a href={globalConfig.mallurl+'/index.php/Dashboard/index'} className="top-section-wrapper-leftnav-item  inlineblock-middle">
				          <i className="fa fa-dashboard"></i>
				          <span>{utils.getLangText(lang,'dashboard')}</span>
			          </a>
			          <a href={globalConfig.mallurl+'/index.php/Market/index'} className="top-section-wrapper-leftnav-item inlineblock-middle">
				          <i className="fa fa-shopping-basket"></i>
				          <span>{utils.getLangText(lang,'market')}</span>
			          </a>
		          </div>
            </div>
            <div className="top-section-wrapper-left-btn inlineblock-middle" onClick={setMobileNavStatus}><div className="fa fa-list"></div></div>
            {isShowMobileNav == 1 && (
             <div className="top-section-wrapper-left-btn-popup" >
                <a href={globalConfig.mallurl+'/index.php/Dashboard/index'} className="top-section-wrapper-left-btn-popupitem">
                <i className="fa fa-dashboard"></i>
				          <span>{utils.getLangText(lang,'dashboard')}</span>
			          </a>
                <a href={globalConfig.mallurl+'/index.php/Market/index'} className="top-section-wrapper-left-btn-popupitem">
				          <i className="fa fa-shopping-basket"></i>
				          <span>{utils.getLangText(lang,'market')}</span>
			          </a>
                <a href={globalConfig.websiteurl} className="top-section-wrapper-left-btn-popupitem" target="_blank">
                 <i className="fa fa-globe"></i>
				          <span>{utils.getLangText(lang,'website')}</span>
			          </a>
               </div>
            )}
            <div className="clear"></div>
          </div>
        </Header>
        <Section>
          <img src={require('./resource/login.png')} className="sign-icon"/>
          <Authenticate />
          <div className="login-bottom">
            <div className="login-bottom-wrapper">{utils.getLangText(lang,'bottom_text_left')}<a className="login-bottom-wrapper-a" target="_blank" href="https://blocto.portto.io/zh/">{utils.getLangText(lang,'bottom_text_right')}</a></div>
          </div>
        </Section>
        <Footer className="footer">
          © 2022 OPENVIEW.WORLD
        </Footer>
      </Wrapper>
    );
  }
}




export default App;


