import React, { useState, useEffect } from "react"
import * as fcl from "@blocto/fcl"
import Alert from "../components/alert";
import globalConfig from '../globalConfig';
import * as utils from '../utils';

var postrun = false; //当前是否已提交登录信息至服务器
var clicklogin = false;  //是否手动点击登录


let addr = getQueryVariable('addr');
let formpage = getQueryVariable('formpage');
 

const SignInOutButton = ({ user }) => {
  const signInOrOut = async (event) => {
    event.preventDefault()

    if (user.loggedIn) {
      provisionaccountTransaction(user)
    } else {
      clicklogin = true;
      fcl.authenticate()
    
    }
  }

  return (
    <div className="sign-button blue" onClick={signInOrOut}>
      {user.loggedIn ? 'Provision Account' : 'Login&SignIn with Blocto Wallet'}
    </div>
  )
}



const failAddrAlert=()=>{
  Alert.open({
      alertTip:"当前登录钱包非待激活钱包，请重新选择钱包登录",
      closeAlert:function(){
          fcl.unauthenticate(); 
          console.log("关闭了...");
      }
  });
}
const provisionaccountTransaction = async (userinfo) => {

  try {
    const { response } = await  fcl.send([
      fcl.limit(300),
      fcl.transaction`
      import HenosisNonFungibleTokenContract from 0xf8d6e0586b0a20c7
      import HenosisNonFungibleToken from 0xf8d6e0586b0a20c7
      transaction {
          prepare(acct: AuthAccount) {
              if acct.borrow<&HenosisNonFungibleTokenContract.Collection>(from: /storage/NFTCollection) != nil {
                  return
              }
              let collection <- HenosisNonFungibleTokenContract.createEmptyCollection()
              acct.save(<-collection, to: /storage/NFTCollection)
  
              acct.link<&{HenosisNonFungibleTokenContract.HenosisNonFungibleTokenCollectionPublic, HenosisNonFungibleToken.CollectionPublic}>(
                  /public/NFTCollection,
                  target: /storage/NFTCollection
              )
              let collectionRef = acct.getCapability<&{HenosisNonFungibleTokenContract.HenosisNonFungibleTokenCollectionPublic, HenosisNonFungibleToken.CollectionPublic}>(/public/NFTCollection)
              assert(collectionRef.borrow() != nil, message: "Missing or mis-typed Collection")
          }
      }
      `,
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization,
      ]),
      fcl.payer(fcl.currentUser().authorization),
    ])
    console.log(response)
    //var transaction = await fcl.tx(response).onceSealed();
    //console.log(transaction) // The transactions status and events after being sealed
    if(!response){
      const bodydata = {'addr':userinfo.addr,'email':userinfo.services[0].scoped.email}
      fetch(globalConfig.mallurl+'/index.php/Member/provisionaccount',{
        method:'POST',
        //mode:'no-cors',
        headers:{
            //'Accept':'application/json,text/plain,*/*',
            'Content-Type':'application/x-www-form-urlencoded'
        },
        body:JSON.stringify(bodydata)
      }).then((response)=>{
        return response.json()
      }).then((data)=>{
        console.log(data)
        if(data.errno == 0){
          if(formpage =='login'){
            window.location.href=globalConfig.mallurl+"/index.php/Index/index/loginSign/"+data.data.sign;
          }else if(formpage =='withdraw'){
            window.location.href=globalConfig.mallurl+"/index.php/Member/withdrawnft";
          }
        } 
      }).catch(function(error){
        console.log(error)
      })
     
    }

  } catch (error) {
    console.error(error);
  }
  
  
 
 
}

const CurrentUser = () => {
  const [user, setUser] = useState({})
  useEffect(() =>
    fcl
      .currentUser()
      .subscribe(user => setUser({ ...user }))
    , [])
  if(user && user.loggedIn && clicklogin && !postrun){
      if(user.addr != addr){
        failAddrAlert();
      }
  }
  return (
      <SignInOutButton user={user} />
  )
}

function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}

export default CurrentUser
