import * as fcl from "@blocto/fcl"

fcl.config()
  /*
  未知备份
  .put("accessNode.api", "http://localhost:8080") // local Flow emulator
  .put("discovery.wallet", "http://localhost:8701/fcl/authn")  
  .put("0xSERVICE", "0xf8d6e0586b0a20c7")*/



  /*
  本地开发版本接口
  .put("challenge.handshake", "http://localhost:8701/flow/authenticate") // local dev wallet
  .put("challenge.scope", "email") // request for Email
  .put("accessNode.api", "https://access-testnet.onflow.org") // Flow testnet
  .put("discovery.wallet", "https://flow-wallet-testnet.blocto.app/api/flow/authn") // Blocto testnet wallet
  .put("discovery.wallet.method", "HTTP/POST")
  .put("service.OpenID.scopes", "email!")
*/

/* 2024.9.9
  .put("challenge.handshake", "https://flow-wallet.blocto.app/authn") // local dev wallet
  .put("challenge.scope", "email") // request for Email
  .put("accessNode.api", "https://flow-access-mainnet.portto.io") // Flow testnet
  .put("service.OpenID.scopes", "email!")
*/

    .put("challenge.handshake", "https://flow-wallet.blocto.app/authn") // local dev wallet
    .put("challenge.scope", "email") // request for Email
    .put("accessNode.api", "https://rest-mainnet.onflow.org") // Flow testnet
    .put("discovery.wallet", `https://wallet-v2.blocto.app/-/flow/authn`) // Flow testnet
    .put("service.OpenID.scopes", "email!")